// Dependencies
///////////////////////////////////////////////
///////////////////////////////////////////////
// Don't import jquery from yarn dependencies anymore, use WordPress jQuery to avoid double lib load...
//import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper, { Navigation, Pagination } from 'swiper';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger)

import Choices from 'choices.js'

import WaveSurfer from 'wavesurfer.js'


// Utils
///////////////////////////////////////////////
///////////////////////////////////////////////
import * as utils from './utils/utils';
import Router from './utils/router';

// Components
///////////////////////////////////////////////
///////////////////////////////////////////////
import Popin from './components/Popin/Popin'

// Layout
///////////////////////////////////////////////
///////////////////////////////////////////////
import layout from './layout/layout'
import header from './layout/header';
import mobileNav from './layout/mobileNav';
import footer from './layout/footer';
import popinVideo from './layout/popinVideo';
import shortcuts from './layout/shortcuts';
import favorites from './layout/favorites';
import facet from './layout/facet';
import pcForm from './layout/pcForm';
import newsletter from './layout/newsletter';
import share from './layout/share';
import searchAside from './layout/searchAside';
import multiSelect from './layout/multiSelect';
import pagination from './layout/pagination';
import readmore from './layout/readmore';

// Pages
///////////////////////////////////////////////
///////////////////////////////////////////////
import t09TabList from './pages/t09-tab-list';
import t10Search from './pages/t10-search';

// Blocks
///////////////////////////////////////////////
///////////////////////////////////////////////
import b04MediaVideo from './blocks/b04-media-video'
import b05MediaPhoto from './blocks/b05-mediaPhoto'
import b06VideoGrid from './blocks/b06-video-grid'
import b07LateralAccordion from './blocks/b07-lateralAccordion'
import b09ListPicto from './blocks/b09-listPicto'
import postSummary from './blocks/post-summary';
import b19textList from './blocks/b19-text-list';
import spotButtons from './blocks/spotButtons';
import b22RelatedProgram from './blocks/b22-related-program';
import audioWidget from "./blocks/audio-widget";
import comments from "./blocks/comments";

// Modules
///////////////////////////////////////////////
///////////////////////////////////////////////
import timezone from "./module/timezone";

// Routing
///////////////////////////////////////////////
///////////////////////////////////////////////
const routes = new Router([
    {
        'file': layout,
        'dependencies': [utils]
    },
    {
        'file': timezone,
        'dependencies': [utils]
    },
    {
        'file': header,
        'dependencies': [utils, gsap, ScrollTrigger],
        'resolve': '.js-header'
    },
    {
        'file': mobileNav,
        'dependencies': [utils, gsap],
        'resolve': '.js-mobileNav'
    },
    {
        'file': footer,
        'dependencies': [utils],
        'resolve': '.js-footer'
    },
    {
        'file': b04MediaVideo,
        'dependencies': [utils, gsap],
        'resolve': '.js-mediaVideo, .js-tabContent'
    },
    {
        'file': popinVideo,
        'dependencies': [utils, Popin],
        'resolve': '.js-popin-video, .js-tabContent'
    },
    {
        'file': b05MediaPhoto,
        'dependencies': [Swiper, Navigation],
        'resolve': '.js-mediaPhoto, .js-tabContent'
    },
    {
        'file': b06VideoGrid,
        'dependencies': [Swiper, Navigation],
        'resolve': '.js-videoGrid, .js-tabContent'
    },
    {
        'file': b07LateralAccordion,
        'dependencies': [utils, Swiper, Navigation],
        'resolve': '.js-accordion, .js-tabContent'
    },
    {
        'file': b09ListPicto,
        'dependencies': [Swiper, Navigation],
        'resolve': '.js-listPicto, .js-tabContent'
    },
    {
        'file': postSummary,
        'dependencies': [Swiper, Navigation],
        'resolve': '.js-postSummary'
    },
    {
        'file': shortcuts,
        'dependencies': [utils, gsap],
        'resolve': '.js-shortcuts'
    },
    {
        'file': b19textList,
        'dependencies': [],
        'resolve': '.js-textList, .js-tabContent'
    },
    {
        'file': spotButtons,
        'dependencies': [],
        'resolve': '.js-spot, .js-tabContent'
    },
    {
        'file': b22RelatedProgram,
        'dependencies': [Swiper, Pagination],
        'resolve': '.js-relatedProgram, .js-tabContent'
    },
    {
        'file': audioWidget,
        'dependencies': [WaveSurfer],
        'resolve': '.js-audio-widget'
    },
    {
        'file': favorites,
        'dependencies': [utils, Popin],
        'resolve': '.js-card-fav, .js-fav'
    },
    {
        'file': facet,
        'dependencies': [utils, gsap, Choices],
        'resolve': '.js-facet'
    },
    {
        'file': pagination,
        'dependencies': [utils, gsap],
        'resolve': '.js-load-more'
    },
    {
        'file': pcForm,
        'dependencies': [],
        'resolve': '.js-pcForm'
    }, 
    {
        'file': newsletter,
        'dependencies': [utils, Popin],
        'resolve': '.js-popin-newsletter'
    },
    {
        'file': comments,
        'dependencies': [Popin],
        'resolve': '.js-popin-comments'
    },
    {
        'file': share,
        'dependencies': [Popin, gsap],
        'resolve': '.js-popin-share'
    },
    {
        'file': searchAside,
        'dependencies': [utils, gsap],
        'resolve': '.js-search-aside'
    },
    {
        'file': t09TabList,
        'dependencies': [utils],
        'resolve': '.js-tabList-button'
    },
    {
        'file': multiSelect,
        'dependencies': [Choices],
        'resolve': '.js-multiSelect'
    },
    {
        'file': readmore,
        'dependencies': [utils],
        'resolve': '.js-readMore'
    },
    {
        'file': t10Search,
        'dependencies': [utils],
        'resolve': '.tplSearch'
    },
]);

window.addEventListener('DOMContentLoaded', () => routes.load())