export default {
    searchAjax: null,
    resultsContainer: document.querySelector('.js-search-aside-content'),
	init: function(utils, gsap){

        const inputSearch = document.getElementById('js-search-aside-input');

		// Search aside
        /////////////////////////////////////////////////////////////////////////////////
        const timelineSearch = gsap.timeline({ paused: true, onComplete: () => {
            if (inputSearch) {
                inputSearch.focus();
            }
        } })

        timelineSearch.set('.js-search-aside', { display: "block" }, 'start')
                .from('.js-search-aside-layer', { scaleY: 0, duration: 0.5, ease: "power2.out" }, 'start')
                .from('.js-search-aside-anim-item', { autoAlpha: 0, y: 50, duration: 1, stagger: 0.1, ease: "power2.out" }, '-=0.4')
        
        //open
        const searchButton = document.querySelector('.js-header-search')

        if (searchButton) {
            searchButton.addEventListener('click', function(e){
                e.preventDefault()
                timelineSearch.timeScale(1).play()
                utils.disableScroll();
            })
        }

        //close
        const closeSearchButton = document.querySelector('.js-search-aside-close')

        if (closeSearchButton) {
            closeSearchButton.addEventListener('click', function(e){
                e.preventDefault()
                timelineSearch.timeScale(1.5).reverse()
                utils.enableScroll();
            })
        }

        let timeout; 

        inputSearch.addEventListener('input', (e) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                this.handleSearchInput(e.target.value, gsap);
            }, 500);
        })

        this.handleReset(inputSearch);

	},
    /**
     * Search with ajax
     * @param {String} keywords 
     */
    handleSearchInput: function(keywords, gsap){

        if (this.searchAjax) {
            this.searchAjax.abort();
        }

        const loader = document.querySelector('.js-searchAside-loader')

        const form_data = new FormData();
        form_data.append('action', 'search_autocomplete');
        form_data.append('r', keywords);

        this.searchAjax = $.ajax({
                url: wordpress_vars.ajax_url,
                type: 'POST',
                contentType: false,
                processData: false,
                data: form_data,
                beforeSend: () => {
                    if (loader) {
                        loader.classList.add('--active');
                    }
                },
                complete: () => {
                    this.searchAjax = null;
                }
            })
            .done(response => {
                if (!response.success) {
                    if (response) {
                        container.innerHTML = response;
                        return;
                    }
                }

                if (loader) {
                    loader.classList.remove('--active')
                }

                if (response.data && response.data.html) {
                    gsap.set(this.resultsContainer, {autoAlpha: 0})
                    this.resultsContainer.innerHTML = response.data.html;

                    const items = this.resultsContainer.querySelectorAll('.searchCard')
                    const tl = gsap.timeline({paused: true})
                    tl.set(this.resultsContainer, {autoAlpha: 1}, 'start')
                      .from(items, {autoAlpha: 0, duration: 0.5, stagger: 0.0625}, 'start')
                    tl.play()

                } else {
                    this.resultsContainer.innerHTML = '';
                }
            })
            .fail(() => {
                console.log("ajax failed");
            })

    },
    /**
     * Reset search
     * @param {HTMLInputElement} input 
     */
    handleReset: function(input){

        const resetButton = document.querySelector('.js-search-aside-reset')

        if (resetButton) {
            resetButton.addEventListener('click', (e) => {
                e.preventDefault()
                input.value = ''
                this.resultsContainer.innerHTML = '';
            })
        }

    }
}