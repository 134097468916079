// Import class dependencies
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
import gsap from 'gsap';

/**
 * Creates a new Popin
 * @class
 */
class Popin {

    /**
     * @constructor
     * @param {HTMLElement} el : the popin HTML element
     * @param {Object} params - the object options configuration
     */
    constructor(el, params){

        this.el = el ? el : this.handleErrors('no_element');
        this.params = this.getParams(params)
        this.animation = null
        this.isActive = false

        if (!window.allPopin) {
           window.allPopin = []
        }

        window.allPopin.push(this)

        this.init()

    }

    /**
     * @function
     * @name init : main init function
     */
    init(){
        this.initAnimation()
        this.initEvents()
    }

    /**
     * @function
     * @name initEvents : init all events
     */
    initEvents(){
        this.handleOpen()

        if (this.params.closeBtnCSSSelector) {
            this.handleClose()
        }

        this.handleCloseBack()
    }

    /**
     * @function
     * @name handleOpen used to handle opening of the popin
     */
    handleOpen(){

        const btnSelectorFormated = this.params.openBtnCSSSelector.substring(1)
        let btn = null

        window.addEventListener('click', (e) => {
            if (e.target.classList.contains(btnSelectorFormated) || e.target.closest(`.${btnSelectorFormated}`)) {
                e.preventDefault()
                btn = e.target
                this.open(btn);
            }
        })

    }

    /**
     * @function
     * @name handleClose used to handle closing of the popin
     */
    handleClose(){

        const btn = document.querySelector(this.params.closeBtnCSSSelector)
        
        if (!btn) return

        btn.addEventListener('click', (e) => {
            e.preventDefault()
            this.close()
        })

    }

    /**
     * @function
     * @name handleCloseBack used to handle closing of popin
     */
    handleCloseBack(){

        const popinBack = document.querySelector(this.params.popinBackCSSSelector)

        if (!popinBack) return

        popinBack.addEventListener('click', (e) => {
            e.preventDefault()
            this.close()
        })

    }

    /**
     * @function
     * @name initAnimation used to create the popin animation
     */
    initAnimation(){

        const timeline = gsap.timeline({
            paused: true,
            onReverseComplete: () => this.dispatchCustomEvent('closed', this.el)
        })

        const popinContent = this.el.querySelector(this.params.popinContentCSSSelector)
        const popinBack = this.el.querySelector(this.params.popinBackCSSSelector)

        if (!popinContent || !popinBack) {
            this.handleErrors('no_popin_content')
        }

        timeline.set(this.el, { display: 'flex' }, 'start')
                .from(popinBack, { scaleY: 0, duration:0.5, ease:"power2.out" }, 'start')
                .from(popinContent, {y:-50, autoAlpha: 0, duration: 0.3, ease: "power2.out"}, "-=0.1")

        this.animation = timeline

    }

    /**
     * @function
     * @name open used to open the popin
     */
    open(btn){

        this.closeAll()

        this.animation.timeScale(1).play()

        document.body.style.overflow = 'hidden';

        this.dispatchCustomEvent('opened', this.el, {
            clickedEl: btn
        })

        this.isActive = true

    }

    /**
     * @function
     * @name close used to close the popin
     */
    close(){

        this.animation.timeScale(2).reverse()
        this.isActive = false

        document.body.style.overflow = '';

    }

    /**
     * @function
     * @name closeAll used to close all popin instance before open new one
     */
    closeAll(){

        const allPopin = window.allPopin

        for (let index = 0; index < allPopin.length; index++) {
            const popin = allPopin[index];

            if (popin.isActive) {
                popin.close()
            }
        }

    }

    /**
     * @function
     * @name on used to listen custom event on el
     * @param {string} eventName : name of event
     * @param {function} callback : function executed when this event is triggered
     * 
     * Available events : 'opened', 'closed'
     */
    on(eventName, callback){
        this.el.addEventListener(eventName, callback)
    }

    /**
     * @function
     * @name getParams Get params to configure the class
     * @param {object} object passed in constructor
     * @return {object} mix between default paramas and params in constructor
     */
    getParams(params){

        const defaultParams = {
            openBtnCSSSelector: '.js-popin-open',
            closeBtnCSSSelector: false,
            popinBackCSSSelector: '.js-popin-back',
            popinContentCSSSelector: 'js-popin-content'
        }

        return {...defaultParams, ...params}

    }

    /**
     * @function
     * @name dispatchCustomEvent used to dispatch custom event on element
     * @param {string} eventName : name of event dispatched
     * @param {HTMLDivElement} element : HTML element to dispatch event on
     * @param {Object} detailObject : custom detail object for event
     */
    dispatchCustomEvent(eventName, element, detailObject = null){
        const event = new CustomEvent(eventName, { detail: detailObject })
        element.dispatchEvent(event);
    }

    /**
     * @function
     * @name handleErrors console log message errors by keys
     * @param {string} key of errors
     */
    handleErrors(key){

        const errors = {
            no_element: "[Popin] - The popin main element doesn't exist in DOM. Please provide a valid HTMLElement.",
            no_popin_content: "[Popin] - The popin content doesn't exist in DOM. Please provide a valid HTMLElement."
        }

        throw new Error(errors[key]);

    }


}

export default Popin;