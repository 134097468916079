export default {
	init: (utils) => {

        // Set CSS Variables
        ////////////////////////////////////
        utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`)
        window.addEventListener('resize', () => utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`))

        // Title space
        ////////////////////////////////////
        function addNonBreakingSpace(string) {
            const lastSpace = string.lastIndexOf(' ');
            
            if (lastSpace === -1) {
                return string;
            }

            const lastWord = string.substring(lastSpace + 1);
            const newString = string.substring(0, lastSpace) + String.fromCharCode(160) + lastWord;
        
            return newString;
        }

        if (window.innerWidth > 1020) {
            const headings = document.querySelectorAll('h1, h2, h3, h4, h5')
            if (headings.length) {
                headings.forEach(heading => {
                    heading.textContent = addNonBreakingSpace(heading.textContent)
                })
            }
        }

        //Iframe
        // TODO = CREATE new FILE for this : B31-iframe
        const iframeContainers = document.querySelectorAll('.js-iframe-container');
        if (iframeContainers.length) {
            iframeContainers.forEach(item => {

                const iframe = item.querySelector('iframe');

                if (!iframe) {
                    return
                }

                window.addEventListener("message", (event) =>{
                    if (event.data && event.data.type === 'updateHeight') {
                        if (iframe) {
                            iframe.style.height = event.data.height + 20 + 'px';
                        }
                    }else{
                        if(event.data && event.data.type === 'getClick' || event.data && event.data.type === 'getClickReturn'){
                            window.scrollTo({
                                top: iframe.offsetTop,
                                left:0,
                                behavior: "smooth",
                            });
                        }
                    }
                })

            })
        }
        
        

    }
}