export default {
	pagedInput: null,
	loadMoreButton: null,
	loadMoreAjax: null,

	init: function(utils, gsap) {
		this.pagedInput = document.querySelector('.js-facet-hidden[name=paged]');
		this.loadMoreButton = document.querySelector('.js-load-more');
		this.bindLoadMore();
	},

	bindLoadMore: function() {
		this.loadMoreButton.addEventListener('click', () => {
			this.loadMoreButton.classList.add('--is-loading')
			this.ajaxSubmitLoadMore();
		})
	},

	ajaxSubmitLoadMore: function() {
		// If doing ajax request, abort it !
		if (this.loadMoreAjax) {
			this.loadMoreAjax.abort();
		}

		const form_data = new FormData();
		form_data.append('action', 'post_list');
		form_data.append('url', new URL(location));

		// Increment pagination num
		this.pagedInput.value = (parseInt(this.pagedInput.value) || 0) + 1;

		document.querySelectorAll('.js-facet-hidden').forEach(facet => {
			// must encodeURI because with complex php array we have annoying backslashes...
			form_data.append(facet.getAttribute('name'), encodeURI(facet.value));
		})

		this.loadMoreAjax = $.ajax({
				url: wordpress_vars.ajax_url,
				type: 'POST',
				contentType: false,
				processData: false,
				data: form_data,
				complete: () => {
					this.loadMoreButton.classList.remove('--is-loading')
					this.loadMoreAjax = null;
				}
			})
			.done(response => {
				if (!response.success) {
					console.log("ajax response success false")
				}

				this.loadMoreButton.classList.remove('--hidden')

				if (response && response.data) {
					if (response.data.hide_load_more_button) {
						this.loadMoreButton.classList.add('--hidden')
					}
					if (response.data.order_by_cf_current_section) {
						const input_order_by_cf_current_section = document.querySelector('.js-facet-hidden[name=order_by_cf_current_section]')
						if (input_order_by_cf_current_section) {
							input_order_by_cf_current_section.value = response.data.order_by_cf_current_section;
						}
					}
					if (response.data.html) {
						document.querySelector('.js-list').innerHTML += response.data.html;
					}
				}
			})
			.fail(() => {
				console.log("ajax failed");
			})
	},
}
