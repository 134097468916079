export default {
    ajaxRequest: null,
	init: function(Popin){
		
        // Spot display more
        /////////////////////////////////////////////////////////////////////////////
        const spotButtons = document.querySelectorAll('.js-spot-button')

        const popinSpotsEl = document.querySelector('.js-popin-spots')
        if (popinSpotsEl) {

            const popinSpotsInstance = new Popin(popinSpotsEl, {
                openBtnCSSSelector: '.js-spot-button',
                closeBtnCSSSelector: '.js-popin-spots-close',
                popinBackCSSSelector: '.js-popin-spots-back',
                popinContentCSSSelector: '.js-popin-spots-content'
            })

            popinSpotsInstance.on('opened', (event) => {
                const post_id = event.detail.clickedEl.getAttribute('data-post-id');
                if (post_id) {
                    this.getSpotsAjax(parseInt(post_id));
                } else {
                    console.error('No post id data found on button')
                }
                
            })
        }

	},
    getSpotsAjax: function(post_id) {
        
		if (this.ajaxRequest) {
			this.ajaxRequest.abort();
		}

        console.log(post_id);

		const form_data = new FormData();
		form_data.append('action', 'get_exhibitor_spots');
		form_data.append('post_id', post_id);

        const resultContainer = document.querySelector('.js-popin-spots-body')

		this.ajaxRequest = $.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			contentType: false,
			processData: false,
			data: form_data,
			complete: () => {
				this.ajaxRequest = null;
			}
		})
			.done(response => {
				if (resultContainer) {
                    resultContainer.innerHTML = response;
                }
			})
			.fail(() => {
				console.log("ajax failed");
			})
	},
}