export default {
	url: new URL(location),
	facetAjax: null,
	multiSelectList: {},
	pagedInput: null,
	loadMoreButton: null,
	counterTotal : 0,

	init: function(utils, gsap, Choices) {
		this.pagedInput = document.querySelector('.js-facet-hidden[name=paged]');
		this.loadMoreButton = document.querySelector('.js-load-more');

		this.launchAll(gsap, Choices);
		this.handleListMobile(gsap);

		document.querySelector('body').addEventListener('dom:updated', () => {
			this.launchAll(gsap, Choices);
		})
	},

	launchAll: function(gsap, Choices){
		this.url = new URL(location);
		
		this.animateFacetLevel1(gsap);
		this.animateFacetLevel2(gsap);

		this.bindFiltering();
		this.bindFacetCard();
		this.bindResetFilter();
		this.bindMultiSelect(Choices);

		this.updateAllFacetCount();
		this.updateFacetTotal();
		this.addFacetCardToSearchParams();
		this.historyPushState();
	},

	animateFacetLevel1: function(gsap) {
		// Ignore facet with "r" key, it is not in dropdown
		document.querySelectorAll('.js-facet:not([data-facet-key=r])').forEach((facet, index) => {
			const facetHead = facet.querySelector('.js-facet-head');
			if (!facetHead) {
				// equivalent to 'continue'
				return;
			}

			const facetList = facet.querySelector('.js-facet-list');
			const facetListItems = facet.querySelectorAll('.js-facet-list > .js-facet-list-item');

			const level1Tl = gsap.timeline({reversed: true, paused: true });
			level1Tl
				.from(facetList, { autoAlpha: 0, paddingTop: 0, paddingBottom: 0, duration: 0.3, ease: "power2.inOut" })
				.fromTo(facetList, { height: 0 }, { height: 'auto', duration: 0.3, ease: "power2.inOut" }, '<')
				.from(facetListItems, { autoAlpha: 0, y: 30, duration: 0.3, stagger: 0.03, ease: "power2.out" }, '<0.1')

			// Open facet with '--unfold' class
			if (facet.classList.contains('--unfold')) {
				level1Tl.play();
			}

			facetHead.addEventListener('click', function(e){
				facetHead.classList.toggle('--opened');
				level1Tl.reversed() ? level1Tl.play() : level1Tl.reverse();
			})
		})
	},

	animateFacetLevel2: function(gsap) {
		document.querySelectorAll('.js-facet-list-item').forEach(facetListItem => {
			const childToggle = facetListItem.querySelector('.js-facet-list-child-toggle');
			if (childToggle !== null)  {
				const childList = facetListItem.querySelector('.js-facet-list-child-list');
				const childListItems = facetListItem.querySelectorAll('.js-facet-list-child-list > .js-facet-list-child-list-item');

				const level2Tl = gsap.timeline({reversed: true, paused: true });
				level2Tl
					.from(childList, { autoAlpha: 0, paddingTop: 0, paddingBottom: 0, duration: 0.3, ease: "power2.inOut" })
					.fromTo(childList, { height: 0 }, { height: 'auto', duration: 0.3, ease: "power2.inOut" }, '<')
					.from(childListItems, { autoAlpha: 0, y: 30, duration: 0.3, stagger: 0.03, ease: "power2.out" }, '<0.1')

				childToggle.addEventListener('click', function(e){
					childToggle.classList.toggle('--opened')
					level2Tl.reversed() ? level2Tl.play() : level2Tl.reverse();
				})
			}
		})
	},

	bindMultiSelect: function(Choices) {
		document.querySelectorAll('.js-facetFilter').forEach((facetFilter, index) => {
			if (facetFilter.classList.contains('js-facetFilter-multiSelect')) {
				this.multiSelectList[index] = new Choices(facetFilter, {
					searchEnabled: false,
					searchChoices: false,
					removeItemButton: true,
					allowHTML: true,
					placeholderValue: 'Sélectionner une valeur',
					classNames: {
						containerOuter: 'multiSelect',
						containerInner: 'multiSelect__inner',
						input: 'multiSelect__input',
						inputCloned: 'multiSelect__input--cloned',
						list: 'multiSelect__list',
						listItems: 'multiSelect__list--multiple',
						listSingle: 'multiSelect__list--single',
						listDropdown: 'multiSelect__list--dropdown',
						item: 'multiSelect__item',
						itemSelectable: 'multiSelect__item--selectable',
						itemDisabled: 'multiSelect__item--disabled',
						itemOption: 'multiSelect__item--choice',
						group: 'multiSelect__group',
						groupHeading : 'multiSelect__heading',
						button: 'multiSelect__button'
					},
					loadingText: 'Chargement...',
					noResultsText: 'Aucun résultat trouvé',
					noChoicesText: 'Aucun choix possible',
					itemSelectText: '', // Clique pour choisir
					uniqueItemText: 'Seulement une valeur peut être ajoutée',
					customAddItemText: 'Seulement les valeurs correspondantes à des conditions spécifiques peuvent être ajoutées',
					addItemText: (value) => {
						return `Tapez Entrée pour ajouter <b>"${value}"</b>`;
					},
					maxItemText: (maxItemCount) => {
						return `Seuelement ${maxItemCount} valeurs peuvent être ajoutées`;
					},
				});
			}
		})
	},

	bindFiltering: function() {
		document.querySelectorAll('.js-facetFilter').forEach((facetFilter, index) => {
			// 'input' event is the best for all type of fields
			// but multiselect using Choices.js lib must use 'change' event to work
			if (facetFilter.classList.contains('js-facetFilter-multiSelect')) {
				facetFilter.addEventListener('change', e => {
					this.filteringListenerCallback(e);
				})
			} else {
				facetFilter.addEventListener('input', e => {
					this.filteringListenerCallback(e);
				})
			}
		})
	},

	filteringListenerCallback: function(e) {
		this.buildUrlWithActiveFacets();
		this.ajaxSubmitFilter();
		this.updateFacetCount(e.currentTarget.closest('.js-facet'));
		this.updateFacetTotal()
	},

	bindFacetCard: function() {
		const AllFacetCard = document.querySelectorAll('.js-facetCard')
		AllFacetCard.forEach(facetCard => {
			facetCard.addEventListener('click', e => {
				const card = e.currentTarget;

				// Update css active effect
				AllFacetCard.forEach(fc => {
					fc.classList.remove('--selected')
				})
				card.classList.add('--selected')

				this.buildUrlWithActiveFacets();

				// Submit ajax
				this.ajaxSubmitFilter();
			})
		})
	},

	addFacetCardToSearchParams: function(facetCard) {
		facetCard = facetCard || document.querySelector('.js-facetCard.--selected')
		if (!facetCard) {
			return false;
		}

		// Build URL param
		const card_key = facetCard.getAttribute('data-key');
		const card_value = facetCard.getAttribute('data-value');
		if (card_value.length > 0) {
			this.url.searchParams.set(card_key, encodeURI(card_value));
		} else {
			this.url.searchParams.delete(card_key);
		}
	},

	bindResetFilter: function() {
		document.querySelector('.js-facetFilter-reset').addEventListener('click', e => {
			document.querySelectorAll('.js-facetFilter').forEach((facetFilter, index) => {
				if (facetFilter.tagName === 'SELECT') {
					if (facetFilter.classList.contains('js-facetFilter-multiSelect')) {
						this.multiSelectList[index].removeActiveItems();
					} else {
						facetFilter.selectedIndex = -1;
					}
				} else if (facetFilter.type === 'checkbox' || facetFilter.type === 'radio') {
					facetFilter.checked = false;
				} else {
					facetFilter.value = '';
				}
			})

			// Clear url of all params
			this.url.search = '';
			// Restore only the facet card, it is mandatory
			this.addFacetCardToSearchParams();
			this.historyPushState();

			// Update counters
			this.updateAllFacetCount();

			this.ajaxSubmitFilter();

			const event = new CustomEvent('facets:reset');
			document.querySelector('body').dispatchEvent(event)
		})
	},

	updateAllFacetCount: function() {
		document.querySelectorAll('.js-facet').forEach(facet => {
			this.updateFacetCount(facet)
		})
	},
	
	updateFacetCount: function(facet) {
		const facetCounter = facet.querySelector('.js-facet-counter');
		if (!facetCounter) {
			// equivalent to 'continue'
			return;
		}

		const facet_type = facet.getAttribute('data-facet-type');
		let counter;

		if (facet_type === 'select') {
			const select_field = facet.querySelector('.js-facetFilter');
			counter = select_field.selectedOptions.length;
			if (counter === 1 && select_field.selectedOptions[0].value === '') {
				counter = 0;
			}
		} else {
			let checkedFacets = facet.querySelectorAll('.js-facetFilter:checked');
			counter = checkedFacets.length;
		}

		facetCounter.innerText = counter;
	},

	updateFacetTotal: function(){
		this.counterTotal = 0;
		const button = document.querySelector('.js-list-side-open')
		if (!button) return;

		const buttonCounter = button.querySelector('.js-button-append')

		document.querySelectorAll('.js-facet').forEach(facet => {
			const facetCounter = facet.querySelector('.js-facet-counter');
			if (!facetCounter) return;
			const counter = parseInt(facetCounter.textContent)
			this.counterTotal += counter;
		})

		buttonCounter.textContent = this.counterTotal
	},

	buildUrlWithActiveFacets: function() {
		// Classic facets
		document.querySelectorAll('.js-facet').forEach(facet => {
			const facet_key = facet.getAttribute('data-facet-key');
			const facet_type = facet.getAttribute('data-facet-type');
			let facet_value;

			if (facet_type === 'select') {
				facet_value = [];
				const selectedOptions = facet.querySelector('.js-facetFilter').selectedOptions;
				Array.from(selectedOptions).forEach(option => {
					facet_value.push(option.value);
				})
				facet_value = facet_value.join(',');
			} else if (facet_type === 'text') {
				facet_value = facet.querySelector('.js-facetFilter').value;
				// Force empty value if smaller than 3 to avoid small words
				if (facet_value.length < 3) {
					facet_value = '';
				}
			} else {
				facet_value = [];
				facet.querySelectorAll('.js-facetFilter:checked').forEach(facetFilter => {
					facet_value.push(facetFilter.value);
				})
				facet_value = facet_value.join(',');
			}

			if (facet_value.length > 0) {
				this.url.searchParams.set(facet_key, encodeURI(facet_value));
			} else {
				this.url.searchParams.delete(facet_key);
			}
		})

		// Facet cards (For now we only have 1 facet card type on a page)
		// Maybe do something else if we have a page with differents facet cards
		this.addFacetCardToSearchParams();

		this.historyPushState();
	},

	historyPushState: function() {
		history.pushState({}, "", this.url);
	},

	ajaxSubmitFilter: function() {
		// If doing ajax request, abort it !
		if (this.facetAjax) {
			this.facetAjax.abort();
		}

		// Reset pagination to 1 when we filter because we overwrite the list
		if (this.pagedInput) {
			this.pagedInput.value = 1;
		}

		const form_data = new FormData();
		form_data.append('action', 'post_list');
		form_data.append('url', this.url);

		document.querySelectorAll('.js-facet-hidden').forEach(facet => {
			// must encodeURI because with complex php array we have annoying backslashes...
			form_data.append(facet.getAttribute('name'), encodeURI(facet.value));
		})

		this.facetAjax = $.ajax({
				url: wordpress_vars.ajax_url,
				type: 'POST',
				contentType: false,
				processData: false,
				data: form_data,
				complete: () => {
					this.facetAjax = null;
				}
			})
			.done(response => {
				if (!response.success) {
					console.log("ajax response success false", response)

					if (response) {
						document.querySelector('.js-list').innerHTML = response;
						return;
					}
				}

				if (this.loadMoreButton) {
					if (response.data.hide_load_more_button) {
						this.loadMoreButton.classList.add('--hidden')
					} else {
						this.loadMoreButton.classList.remove('--hidden')
					}
				}

				if (response.data.html) {
					document.querySelector('.js-list').innerHTML = response.data.html;

					const event = new CustomEvent('list:updated')
					const body = document.querySelector('body')
					body.dispatchEvent(event);
				}
			})
			.fail(() => {
				console.log("ajax failed");
			})
	},

	handleListMobile: function(gsap){

		const openListButton = document.querySelector('.js-list-side-open')
		
		if (openListButton) {
			const openListButtonText = openListButton.querySelector('.js-button-body').textContent;
			const timeline = gsap.timeline({paused: true})
			const listSideItems = gsap.utils.toArray('.js-list-side-item');

			timeline.set('.js-list-side', {display: 'block'}, 'start')
			        .from('.js-list-side', {scaleY: 0, duration: 0.4, ease: 'power2.out'}, 'start')
					.from(listSideItems, {autoAlpha: 0, y: 40, duration: 0.3, ease: 'power2.out', stagger: 0.035}, '-=0.3')
			
			openListButton.addEventListener('click', (e) => {
				if (openListButton.classList.contains('--active')) {
					timeline.timeScale(2).reverse()
					openListButton.classList.remove('--active')
					openListButton.querySelector('.js-button-body').textContent = openListButtonText;
				} else {
					timeline.timeScale(1).play()
					openListButton.classList.add('--active')
					this.updateFilterButtonMobile()
				}
			})

			const handleResize = () => {
				if (window.innerWidth > 1020) {
					timeline.seek(1).pause()
				} else {
					timeline.seek(0).pause()
				}
			}

			handleResize()

			window.addEventListener('resize', () => { 
				handleResize()
			})
		}

	}, 

	/**
	 * 
	 * @param {Number} counter 
	 */
	updateFilterButtonMobile: function(counter){
		const jsListSide = document.querySelector('.js-list-side-container')
		const jsListSideButton = document.querySelector('.js-list-side-open')
		const jsListSideButtonBody = jsListSideButton.querySelector('.js-button-body');

		if(jsListSide && jsListSideButton){
			const jsListSideOpenText = jsListSide.dataset.openText;
			jsListSideButtonBody.textContent = jsListSideOpenText;

			console.log(this.counterTotal)
		}
	}
}
