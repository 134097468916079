import { atcb_action } from "add-to-calendar-button";

export default {
	favAjax: null,

	init: function(utils, Popin) {
		this.handleClickOnCard()
		this.handleClickOnSingle()
		this.bindRemoveFavCardButton()
		this.bindCalendar()
		this.initPopin(Popin)
		this.handlePrint()

		document.querySelector('body').addEventListener('list:updated', () => {
			this.handleClickOnCard()
		})
	},

	bindCalendar: function() {
		const button = document.querySelector('.js-favorite-add-calendar');

		let config = {
			name: "Favoris",
			options: ['Google', 'iCal', 'Outlook.com'],
			timeZone: wordpress_vars.timezone_string,
			language: 'fr'
		};

		if (button) {
			button.addEventListener('click', () => {
				const wrapper = document.querySelector('.js-favorite-list-wrapper');
				if (wrapper) {
					config.dates = JSON.parse(wrapper.dataset.calendarDates);
					atcb_action(config, button);
				}
			});
		}
	},

	handleClickOnCard: function() {
		const buttons = document.querySelectorAll('.js-card-fav-button');

		if (buttons.length) {
			buttons.forEach(button => {

				const card = button.closest('.js-card-fav');

				if (card) {
					const postInformations = this.getPostInformations(card);

					button.addEventListener('click', (e) => {
						e.preventDefault()
						button.classList.toggle('--active');
						this.toggleFavInCookie(postInformations);
					})
				}

			})
		}
	},

	handleClickOnSingle: function() {
		const buttons = document.querySelectorAll('.js-fav-button');

		if (buttons.length) {
			buttons.forEach(button => {

				const buttonParent = button.closest('.js-fav')

				if (buttonParent) {
					const postInformations = this.getPostInformations(buttonParent);

					button.addEventListener('click', (e) => {
						e.preventDefault()
						button.classList.toggle('--active');
						this.toggleFavInCookie(postInformations);
					})
				}

			})
		}
	},

	bindRemoveFavCardButton: function() {
		const buttons = document.querySelectorAll('.js-popinFavorite-card-remove');
		if (buttons.length) {
			buttons.forEach(button => {
				const card = button.closest('.js-popinFavorite-card');
				if (card) {
					const postInformations = this.getPostInformations(card);
					const card_fav_id = card.dataset.favId;

					button.addEventListener('click', (e) => {
						e.preventDefault()

						if (document.querySelector('.js-card-fav[data-fav-id="'+card_fav_id+'"] .js-card-fav-button')) {
							document.querySelector('.js-card-fav[data-fav-id="'+card_fav_id+'"] .js-card-fav-button').classList.remove('--active');
						}

						if (document.querySelector('.js-search-card[data-fav-id="'+card_fav_id+'"]')) {
							document.querySelector('.js-search-card[data-fav-id="'+card_fav_id+'"]').remove()
						}
						
						this.toggleFavInCookie(postInformations);
					})
				}
			})
		}
	},

	/**
	 * Get post informations from HTML Element
	 * @param {HTMLElement} element 
	 * @returns {Object} postInfos
	 */
	getPostInformations: function(element){
		let favoriteType;

		switch (element.dataset.postType) {
			case 'program':
				favoriteType = 'favorites_program';
				break;
			case 'exhibitor':
				favoriteType = 'favorites_exhibitor';
				break;
			default:
				favoriteType = 'favorites';
		}

		const postInfos = {
			post_type: element.dataset.postType,
			fav_id: element.dataset.favId,
			cookie_name: favoriteType
		}

		return postInfos;
	},

	/**
	 * @function toggleFavInCookie : add or remove post in favorites cookie
	 * @param {String} cookieName : the name of the cookie
	 * @param {Object} postInformations : the object containing the post informations
	 */
	toggleFavInCookie: function(postInformations) {

		const existingFavorites = this.getCookie(postInformations.cookie_name);
		let existingFavoritesObjects = existingFavorites ? JSON.parse(existingFavorites) : [];

		if (existingFavoritesObjects.includes(postInformations.fav_id)) {
			this.removePostFromFavorites(postInformations.cookie_name, postInformations)
		} else {
			existingFavoritesObjects.push(postInformations.fav_id);
			this.setFavoriteAsCookie(postInformations.cookie_name, JSON.stringify(existingFavoritesObjects), 30);
		}

		this.ajaxBuildFavoriteList(postInformations.cookie_name);
		this.setFavoritesCount(postInformations.cookie_name);
	},

	/**
	 * @function setFavoriteAsCookie
	 * @param {String} cookieName 
	 * @param {String} cookieValue
	 * @param {Number} expirationDays 
	 */
	setFavoriteAsCookie: function(cookieName, cookieValue, expirationDays){

		const date = new Date();
		date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
		const expires = "expires=" + date.toUTCString();
		document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";

	},

	/**
	 * @function getCookie
	 * @param {String} key
	 * @returns the cookie value
	 */
	getCookie: function(key) {
		const name = key + "=";
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookieArray = decodedCookie.split(';');
		for (let i = 0; i < cookieArray.length; i++) {
			let cookie = cookieArray[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return "";
	},

	/**
	 * removePostFromFavorites : remove the post from cookie name
	 * @param {String} cookieName 
	 * @param {Object} postInformations 
	 */
	removePostFromFavorites: function(cookieName, postInformations){
		const existingFavorites = this.getCookie(cookieName);
		let existingFavoritesObjects = existingFavorites ? JSON.parse(existingFavorites) : [];
		
		const indexOfFav = existingFavoritesObjects.indexOf(postInformations.fav_id)
		existingFavoritesObjects.splice(indexOfFav, 1)

		this.setFavoriteAsCookie(cookieName, JSON.stringify(existingFavoritesObjects), 30);
	},

	setFavoritesCount: function(cookieName){
		const button = document.querySelector('.js-popinFavorite-open');
		if (button) {
			const buttonAppend = button.querySelector('.js-button-append');
			const existingFavorites = this.getCookie(cookieName);

			if (buttonAppend && existingFavorites) {
				const existingFavoritesObjects = JSON.parse(existingFavorites);
				buttonAppend.textContent = existingFavoritesObjects.length;
			}
		}
	},

	ajaxBuildFavoriteList: function(cookieName) {
		// If doing ajax request, abort it !
		if (this.favAjax) {
			this.favAjax.abort();
		}

		const form_data = new FormData();
		// TODO : Adapt for each post types
		form_data.append('action', 'post_favorite');
		form_data.append('cookie_name', cookieName);

		this.favAjax = $.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			contentType: false,
			processData: false,
			data: form_data,
			complete: () => {
				this.favAjax = null;
			}
		})
			.done(response => {
				if (document.querySelector('.js-popinFavorite-loader')) {
					document.querySelector('.js-popinFavorite-loader').classList.add('--hidden')
				}

				if (document.querySelector('.js-popinFavorite-list')) {
					document.querySelector('.js-popinFavorite-list').innerHTML = response;
					this.bindRemoveFavCardButton();
				}
			})
			.fail(() => {
				console.log("ajax failed");
			})
	},

	/**
	 * 
	 * @param {Popin} Popin
	 */
	initPopin: function(Popin){
		const popinEl = document.querySelector('.js-popinFavorite')
        if (popinEl) {
            const popinInstance = new Popin(popinEl, {
                openBtnCSSSelector: '.js-popinFavorite-open',
                closeBtnCSSSelector: '.js-popinFavorite-close',
                popinBackCSSSelector: '.js-popinFavorite-back',
                popinContentCSSSelector: '.js-popinFavorite-content'
            })

			//Get favorite list on opened
			popinInstance.on('opened', (event) => {
				const button = event.detail.clickedEl.closest('.js-popinFavorite-open');

				if (button) {
					if (button.classList.contains('js-favorite-button-program')) {
						popinEl.classList.add('--program')
						this.ajaxBuildFavoriteList('favorites_program')
					} else if(button.classList.contains('js-favorite-button-exhibitor')){
						popinEl.classList.add('--exhibitor')
						this.ajaxBuildFavoriteList('favorites_exhibitor')
					}
				}
			})

			popinInstance.on('closed', (event) => {

				popinEl.classList.remove('--exhibitor')
				popinEl.classList.remove('--program')

				if (document.querySelector('.js-popinFavorite-loader')) {
					document.querySelector('.js-popinFavorite-loader').classList.remove('--hidden')
				}

				if (document.querySelector('.js-popinFavorite-list')) {
					document.querySelector('.js-popinFavorite-list').innerHTML = '';
				}
			})

		}
	},
	handlePrint: function(){

		const printButton = document.querySelector('.js-favorite-export-pdf');
		
		if (!printButton) {
			return;
		}

		printButton.addEventListener('click', function(e){
			e.preventDefault()
			window.print()
		})

	}
}